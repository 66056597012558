<!--index:Hi, 收藏 2024/07/03 22:51:52 YuAng-->

<template>
  <div class="collected-index" v-infinite-scroll="getData" :infinite-scroll-immediate=false
    :infinite-scroll-disabled="!loadMore" :infinite-scroll-distance=30>
    <div v-for="article in list" :key="article.id">
      <feed-list-common v-if="article.type === 'POST'" :item="article" :show-header=true :user-info="user">
        <p class="post-msg" slot="header">
          <span>{{ $t('starPost') }}</span>
          <span>{{ new Date(article.gmtCreate).getTime() | formatTimeAgo }}</span>
          <el-tag effect="dark" size="small" type="warning">
            {{ $t('favorite') }}
          </el-tag>
        </p>
      </feed-list-common>
      <feed-list-rich v-else :item="article" :show-header=true :user-info="user">
        <p class="post-msg" slot="header">
          <span>{{ $t('starPost') }}</span>
          <span>{{ new Date(article.gmtCreate).getTime() | formatTimeAgo }}</span>
          <el-tag effect="dark" size="small" type="warning">
            {{ $t('favorite') }}
          </el-tag>
        </p>
      </feed-list-rich>
    </div>
  </div>
</template>
<script>
import FeedListCommon from '../components/FeedListCommon.vue'
import FeedListRich from '@/views/components/FeedListRich.vue'
import { articleApi } from '@/utils/api'
import Index from '@/views/user/index.vue'

export default {
  extends: Index,
  components: { FeedListCommon, FeedListRich },
  name: 'index',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      isloading: false,
      currentPage: 1,
      loadMore: true,
      list: []
    }
  },
  created () {
    this.getData()
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    getData: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      articleApi.getMyInteractionArticles({
        currentPage: this.currentPage,
        pageSize: 10,
        interactionType: 'STAR'
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200) {
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          this.currentPage++
          console.log('收藏', resp.data)
          this.getArticleInteractions(resp.data)
        }
      }).catch(err => {
        console.log('err', err)
        this.isloading = false
      })
    },
    /**
     *  文章互动数据
     * @param {文章 id} articleIds 用逗号分割
     */
    getArticleInteractions: function (articles) {
      if (!articles || articles.length === 0) {
        return
      }
      const articleIds = articles.map(item => item.id).join(',')
      articleApi.getArticleInteractions({
        articleIds: articleIds
      }).then(resp => {
        if (resp.httpStatus === 200) {
          // 为 articles 追加 互动数据
          const interactionMap = new Map(resp.data.map(interaction => [interaction.articleId, interaction]))
          const result = articles.map(article => {
            const interactionData = interactionMap.get(article.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              return {
                ...article,
                likeNum: interactionData.likeNum,
                commentNum: interactionData.commentNum
              }
            } else {
              // 如果没有找到互动数据，则保留原有文章数据
              return article
            }
          })
          this.list = this.list.concat(result)
          console.log('收藏 互动', this.list)
        }
      })
    }
  }
}
</script>

<style scoped>
.collected-index {
  min-height: 700px;
  padding-bottom: 100px;
  overflow-y: auto;
}

.post-msg {
  margin-left: 20px;
  font-weight: bold;
}

.post-msg span {
  margin-right: 20px;
}
</style>
